<template>
  <v-container
    id="register"
    class="fill-height justify-center"
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <v-slide-y-transition appear>
          <v-card
            class="pa-3 pa-md-5 mx-auto"
            light
            v-if="torneo"
          >
            <pages-heading
              class="text-center display-3"
              back
            >
              {{ torneo.nombre }}
            </pages-heading>

            <v-row>
              <v-col
                cols="5"
              >
                <v-list>
                  <v-list-item
                    v-for="(item, index) in jornadas"
                    :key="`jornada-local-${index}`"
                    @click="goToJourney(item.id)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.equipo_local.nombre }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-avatar>
                      <v-img
                        v-if="item.equipo_local.logotipo"
                        :src="item.equipo_local.logotipo"
                        :lazy-src="preloader"
                      ></v-img>
                      <v-avatar color="primary">
                            <span class="white--text">
                              {{ tokenizeName(item.equipo_local.nombre) }}
                            </span>
                      </v-avatar>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col
                cols="2"
              >
                <v-list
                  dense
                >
                  <v-list-item
                    v-for="(item, index) in jornadas"
                    :key="`versus-${index}`"
                  >
                    <v-list-item-content>
                      <v-img
                        :lazy-src="preloader"
                        :src="versusImg"
                        max-width="40"
                        class="center-element"
                      ></v-img>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col
                cols="5"
              >
                <v-list>
                  <v-list-item
                    v-for="(item, index) in jornadas"
                    :key="`jornada-visitante-${index}`"
                    @click="goToJourney(item.id)"
                  >
                    <v-list-item-avatar>
                      <v-img
                        v-if="item.equipo_visitante.logotipo"
                        :src="item.equipo_visitante.logotipo"
                        :lazy-src="preloader"
                      ></v-img>
                      <v-avatar color="primary">
                        <span class="white--text">
                          {{ tokenizeName(item.equipo_visitante.nombre) }}
                        </span>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.equipo_visitante.nombre }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'PagesTorneosDetalle',

    components: {
      PagesBtn: () => import('./components/Btn'),
      PagesHeading: () => import('./components/Heading'),
    },

    data: () => ({
      sucursales: [],
      torneo: null,
      jornadas: [],
      jornadasItems: {
        local: [],
        visitante: []
      },
      torneos: [],
      filters: {
        sucursal: null,
        torneo: null,
      },
      versusImg: require('../../assets/icons/versus.svg'),
    }),
    async mounted() {
      this.toggleLoader()
      this.torneo = await this.getTorneo()
      this.jornadas = await this.getJornadas()
      this.toggleLoader()
    },
    methods: {
      async getTorneo () {
        let torneo = null

        await this.$http.get(route('v1/tournament/'+this.$route.params.id))
        .then(response => {
          if (response.body.code === 200) {
            if (response.body.data.length > 0)
            {
              torneo = response.body.data[0]
            }
          } else {
            this.processError(response)
          }
        }, error => this.processError(error))

        return torneo
      },
      async onSelectedBranch () {
        this.toggleLoader()

        this.torneos = await this.getTorneosSearch({
          filters: {
            sucursal_id: this.filters.sucursal
          }
        })

        this.toggleLoader()
      },
      async getJornadas () {
        let jornadas = []

        await this.$http.post(route('v1/journey/search'), {
          filters: {
            torneo_id: this.$route.params.id
          }
        }).then(response => {
          if (response.body.code === 200) {
            jornadas = response.body.data
          } else {
            this.processError(response)
          }
        }, error => this.processError(error))

        return jornadas
      },
      goToJourney(id) {
        this.$router.push({
          name: 'JourneyDetail',
          params: {
            id: id
          }
        })
      }
    }
  }
</script>

<style lang="sass">
  #register
    .v-list-item__subtitle
      -webkic-line-clamp: initial
      -webkit-box-orient: initial

  .mw50
    max-width: 50% !important
</style>
